import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'

export const useBypassStore = defineStore(
  'bypass',
  () => {
    /** state */
    const checkInDate = ref('')
    const reasonDescription = ref('')
    const scanBypassed = ref(false)

    /** getters */
    const getBypass: ComputedRef<Bypass | null> = computed(() => {
      if (scanBypassed.value) {
        return {
          checkInDate: checkInDate.value || null,
          reason: reasonDescription.value || '',
        }
      }

      return null
    })

    /** actions */
    function setCheckInDate(value: string): void {
      checkInDate.value = value
    }

    function setReasonDescription(value: string): void {
      reasonDescription.value = value
    }

    function setScanBypassed(value: boolean): void {
      scanBypassed.value = value
    }

    function resetBypass() {
      checkInDate.value = ''
      reasonDescription.value = ''
    }

    return {
      checkInDate,
      getBypass,
      reasonDescription,
      resetBypass,
      scanBypassed,
      setCheckInDate,
      setReasonDescription,
      setScanBypassed,
    }
  },
  {
    persist: true,
  },
)
