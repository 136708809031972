<template>
  <div>
    <TitleBanner title="Select Site (Bypass)" />
    <div class="mx-2 mt-4 space-y-4">
      <p class="error text-center font-medium">
        Bypass records are reported separately
      </p>
      <VueDatePicker
        v-model="checkInDate"
        :enable-time-picker="false"
        format="MMMM d yyyy"
        :min-date="minCheckInDate"
        :max-date="maxCheckInDate"
        placeholder="Select check in date"
        :action-row="{
          showNow: false,
          showPreview: false,
          showSelect: false,
          showCancel: false,
        }"
        auto-apply
      />
      <span v-if="dateIsEmpty" class="error ml-1 mt-2 text-sm">Required.</span>
      <BaseTextarea
        v-model="reasonDescription"
        placeholder="Bypass Reason"
        data-test="reason-description"
        :textarea-classes="['h-20']"
        minlength="10"
        :required="textareaRequired"
        :maxlength="100"
        :class="{ 'placeholder-red-500': descriptionIsEmpty }"
      >
      </BaseTextarea>
      <span v-if="descriptionIsEmpty" class="error ml-1 text-sm">
        Required. Must be at least 10 characters
      </span>
    </div>
    <div class="mx-2 mt-4">
      <PrimaryButton
        :key="submitButtonKey"
        class="continue"
        type="button"
        action="CONTINUE"
        @click.once="confirmBypass"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref, watchEffect } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import BaseTextarea from '@/components/BaseTextarea.vue'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { useRouter } from 'vue-router'
  import dayjs from 'dayjs'
  import { useBypassStore } from '@/storage/bypass'
  import config from '@/config'

  const router = useRouter()
  const textareaRequired = ref(true)
  const reasonDescription = ref('')
  const checkInDate = ref<Date | undefined>(undefined)
  const { setCheckInDate, setReasonDescription, resetBypass } = useBypassStore()
  const minCheckInDate = ref(
    dayjs().subtract(config.bypass.maxPastDateSelectionRange, 'month').toDate(),
  )
  const maxCheckInDate = ref(dayjs().toDate())
  const dateIsEmpty = ref(false)
  const descriptionIsEmpty = ref(false)
  const submitButtonKey = ref<number>(0)

  onMounted(() => {
    resetBypass()
  })

  watchEffect(() => {
    if (checkInDate.value) {
      dateIsEmpty.value = false
    }

    if (reasonDescription.value) {
      descriptionIsEmpty.value = false
    }
  })

  function saveBypassInStore() {
    setCheckInDate(formatAndConvertDate(checkInDate.value as Date))
    setReasonDescription(reasonDescription.value.trim())
  }

  async function confirmBypass(): Promise<void> {
    submitButtonKey.value++
    if (checkMandatoryFields()) {
      saveBypassInStore()
      await router.replace({ name: 'selectBypassSites' })
    }
  }

  function formatAndConvertDate(date: Date | string): string {
    return dayjs(date).format('DD-MM-YYYY')
  }

  function checkMandatoryFields() {
    if (!checkInDate.value) {
      dateIsEmpty.value = true
    }
    if (!reasonDescription.value || reasonDescription.value.length < 10) {
      descriptionIsEmpty.value = true
    }

    return !(dateIsEmpty.value || descriptionIsEmpty.value)
  }
</script>
