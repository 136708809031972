import 'es6-promise/auto'
import { createApp } from 'vue'
import App from '@/App.vue'
import '@/index.css'
import '@verifiedit/form-generator/style.css'
import router from '@/router'
import config from '@/config'
import axios from 'axios'
import pinia from '@/storage'
import {
  errorInterceptor,
  eventResponseInterceptor,
  fingerprintRequestInterceptor,
  requestDataInterceptor,
  timezoneRequestInterceptor,
  tokenRequestInterceptor,
  tokenResponseInterceptor,
} from '@/services/api/interceptors'
import { initialiseFingerprint } from '@/services/fingerprint'
import strategyResolver from '@/strategies/strategyResolver'
import { sessionValidator } from '@/resolvers/sessionValidation'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshAuthLogic } from '@/services/api/csrf'
import { useAppStore } from '@/storage/app'
import siteSessionInterceptor from '@/services/api/interceptors/siteSessionInterceptor'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.baseURL = config.api.capture
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'

createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [419] })

const app = createApp(App)
app.use(pinia)
app.use(router)

app.mount('#app')

initialiseFingerprint()
  .then(() => {
    // initialize axios interceptors
    errorInterceptor()
    fingerprintRequestInterceptor()
    eventResponseInterceptor()
    requestDataInterceptor()
    timezoneRequestInterceptor()
    tokenRequestInterceptor()
    tokenResponseInterceptor()
    siteSessionInterceptor()
    sessionValidator().then((sessionIsValid) => {
      console.info(`Session is ${sessionIsValid ? 'valid' : 'invalid'}`)
      strategyResolver()
    })
  })
  .finally(() => {
    useAppStore().setInitialised(true)
  })
