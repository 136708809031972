<template>
  <NavigationSlot :is-overlay="hasOverlay" />
  <ErrorView>
    <NetworkError :show="hasNetworkError">
      <OverlayView @has-overlay="setHasOverlay" />
      <RouterView v-if="initialised" v-slot="{ Component, route }">
        <Suspense timeout="0">
          <template #default>
            <Component :is="Component" v-show="!hasOverlay" />
          </template>
          <template #fallback>
            <LoadingView
              v-show="!hasOverlay"
              :is-loading="true"
              :message="loadingMessage(route)"
            />
          </template>
        </Suspense>
      </RouterView>
    </NetworkError>
  </ErrorView>
  <SnackBar v-if="currentNotification" />
  <LoadingView
    :is-loading="!initialised || isLoading"
    :is-opaque="true"
    :message="message"
  />
  <BroadcastManager @socket-ready="initialiseSocketRequestInterceptor" />
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { RouteLocationNormalizedLoaded, RouterView } from 'vue-router'
  import ErrorView from '@/views/ErrorView.vue'
  import NavigationSlot from '@/components/NavigationSlot.vue'
  import LoadingView from '@/views/LoadingView.vue'
  import { useAppStore } from '@/storage/app'
  import BroadcastManager from '@/components/BroadcastManager.vue'
  import socketRequestInterceptor from '@/services/api/interceptors/socketRequestInterceptor'
  import NetworkError from '@/views/NetworkError.vue'
  import OverlayView from '@/views/OverlayView.vue'
  import { useSessionTerminationBroadcast } from '@/components/use/sessionBroadcast'
  import { useTabFocus } from '@/components/use/browserTabState'
  import {
    initialiseLoadedAt,
    validateSessionOnFocus,
  } from '@/components/use/session'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useNotificationsStore } from '@/storage/notifications'
  import SnackBar from '@/components/SnackBar.vue'

  export default defineComponent({
    name: 'App',
    components: {
      BroadcastManager,
      ErrorView,
      LoadingView,
      NavigationSlot,
      NetworkError,
      OverlayView,
      RouterView,
      SnackBar,
    },

    setup() {
      const { setToken } = useAppStore()
      const { initialised, isLoading, getMessage } = storeToRefs(useAppStore())
      const tabIsFocused = useTabFocus()
      const { currentNotification } = storeToRefs(useNotificationsStore())

      const hasOverlay = ref(false)
      const hasNetworkError = computed(
        () =>
          initialised.value &&
          !useFingerprintStore().hasFingerprint &&
          !isLoading.value,
      )
      // token comes from index.html and is injected via cloudflare worker

      setToken(token)

      onMounted(() => {
        initialiseLoadedAt()
        useSessionTerminationBroadcast()
      })

      watch(tabIsFocused, (_, prevFocus) => {
        if (tabIsFocused.value && !prevFocus) {
          validateSessionOnFocus()
        }
      })

      function initialiseSocketRequestInterceptor(socketId: string) {
        socketRequestInterceptor(socketId)
      }

      function loadingMessage(route: RouteLocationNormalizedLoaded): string {
        return (route.meta.loadingMessage as string) || 'Loading...'
      }

      function setHasOverlay(value: boolean) {
        hasOverlay.value = value
      }

      return {
        currentNotification,
        hasNetworkError,
        hasOverlay,
        initialiseSocketRequestInterceptor,
        initialised,
        isLoading,
        loadingMessage,
        message: getMessage,
        setHasOverlay,
        tabIsFocused,
      }
    },
  })
</script>
