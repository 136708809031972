import {
  ResolvableRouteInterface,
  StrategyInterface,
} from '@/types/strategyInterface'
import { useNativeScanStore } from '@/storage/nativeScan'
import { RouteLocationRaw } from 'vue-router'
import { useSiteStore } from '@/storage/site'
import { useUserStore } from '@/storage/user'

class AuthenticatedUserNativeScanStrategy
  implements StrategyInterface, ResolvableRouteInterface
{
  private readonly uuid

  constructor(uuid: string) {
    this.uuid = uuid
  }

  setup() {
    // check if site is already set from this uuid
    const sameSite =
      useSiteStore().exists && useNativeScanStore().uuid === this.uuid
    useSiteStore().setHasSameSiteSetInSession(sameSite)

    if (!sameSite) {
      useUserStore().setCheckedInTime(null)
    }

    useNativeScanStore().setUuid(this.uuid)
  }

  resolve(): RouteLocationRaw {
    return { name: 'selectNearbySite' } as RouteLocationRaw
  }
}

export default AuthenticatedUserNativeScanStrategy
