import { defineStore } from 'pinia'
import { computed, ComputedRef, reactive, ref } from 'vue'

export const useAppStore = defineStore(
  'app',
  () => {
    /** state */
    const token = ref<string>('')
    const initialised = ref<boolean>(false)
    const loading = reactive<Map<'app' | 'position', boolean>>(
      new Map([
        ['app', false],
        ['position', false],
      ]),
    )
    const loadedAt = ref(0)

    /** getters */
    const getMessage: ComputedRef<string> = computed(() => {
      if (loading.get('app')) {
        return 'Initialising application...'
      }

      return 'Loading...'
    })

    const isLoading: ComputedRef<boolean> = computed(() =>
      [...loading.values()].some((loading) => loading),
    )

    /** actions */
    function setInitialised(value: boolean): void {
      initialised.value = value
    }

    function setLoadedAt(value: number): void {
      loadedAt.value = value
    }

    function setToken(value: string): void {
      token.value = value
    }

    return {
      getMessage,
      initialised,
      isLoading,
      loadedAt,
      loading,
      setInitialised,
      setLoadedAt,
      setToken,
      token,
    }
  },
  {
    persist: {
      omit: ['initialised'],
      storage: sessionStorage,
    },
  },
)
